import React, { useEffect } from "react";
import { getUserByEmail } from "../Firebase/Firebase.utils";

function ProfilePhotoOnly({ email }) {
  const [user, setUser] = React.useState({});

  useEffect(() => {
    const fetchUser = async () => {
      const user = await getUserByEmail("users", email);
      setUser(user);
    };
    fetchUser();
  }, [email]);

  return (
    <div className="flex items-center justify-normal gap-4">
      <img
        src={
          user.profilePhoto
            ? user.profilePhoto
            : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
        }
        className="w-10 h-10 rounded-full"
        style={{ objectFit: "cover" }}
        alt=""
      />
      <div>
        <div className="text-xs font-bold">{user.displayName}</div>
      </div>
    </div>
  );
}

export default ProfilePhotoOnly;
