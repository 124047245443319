import React from "react";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getDocDataSnap, setDocData } from "../../Firebase/Firebase.utils";
import RequirementDetailedInfo from "./RequirementDetailedInfo";
import Button from "../../components/Button";
import ReqDetailedInfoForm from "./ReqDetailedInfoForm";
import CompanyProfile from "./CompanyProfile";
import CompanyProfileForm from "./CompanyProfileForm";
import UserSearch from "../../components/UserSearch";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputBoxSelect from "../../components/InputBoxSelect";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import UserAvatar from "../../components/UserAvatar";
import ProfilePhotoOnly from "../../components/ProfilePhotoOnly";
import TeamNotes from "./TeamNotes";
import ContactList from "./ContactList";
import TaskManagement from "../../OfficeLeasing/TaskManagement";
import { useSearchParams } from "react-router-dom";

function LeasingRequirementDetails() {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeLinkParam = searchParams.get("link");
  const [requirementData, setRequirementData] = useState({});
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const [teamMembers, setTeamMembers] = useState([]);
  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const getRequirementData = async () => {
      const data = await getDocDataSnap("tenantRep", id, (data) => {
        setFormData(data);
        setRequirementData(data);
        const teamMembers = data.team_members.map((member) => {
          return { label: member, value: member };
        });
        setTeamMembers(teamMembers);
        setIsLoading(false);
      });
    };
    getRequirementData();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleClick = (e, user) => {
    setFormData((prevFormData) => ({
      ...(prevFormData || ""),
      team_members: [...(prevFormData.team_members || ""), user.email],
    }));
  };

  const handleSelectTeamLead = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      team_lead: e.value,
    }));
  };

  const pillStyle =
    "bg-gray-200 rounded-full px-2 py-1 text-xs hover:cursor-pointer";
  const activePillStyle =
    "bg-orange-600 text-white rounded-full px-2 py-1 text-xs hover:cursor-pointer";

  const menuItems = [
    "Requirement Details",
    "Company Profile",
    "Team Notes",
    "Options",
    "Tasks",
  ];
  

  const handleEditMode = () => {
    setEditMode(!editMode);
    if (editMode) {
      handleSubmit();
    }
  };

  const handleSubmit = (e) => {
    setEditMode(!editMode);
    if (editMode) {
      const docObject = {
        ...formData,
        updatedAt: new Date().toISOString(),
        lastUpdatedBy: currentUser.email,
      };
      setDocData("tenantRep", id, docObject);
      setEditMode(false);
    }
  };

  const handleDelete = (e, member) => {
    const filteredTeam = formData.team_members.filter((teamMember) => {
      return teamMember !== member;
    });
    setFormData((prevFormData) => ({
      ...prevFormData,
      team_members: filteredTeam,
    }));
  };

  const toggleActiveStatus = () => {
    const docObject = {
      ...formData,
      status: formData.status === "active" ? "inactive" : "active",
      updatedAt: new Date().toISOString(),
      lastUpdatedBy: currentUser.email,
    };
    setDocData("tenantRep", id, docObject);
    toast.success("Status Updated");
  };

  const handleActiveMenu = (index) => {
    setSearchParams({ link: menuItems[index] });
  };

  return (
    <div>
      <div className="bg-white rounded-xl shadow-lg py-2 px-4 relative">
        <div className="absolute top-2 right-2">
          <button
            className={
              requirementData.status === "active"
                ? "bg-green-500 px-2 py-1 rounded-lg text-xs"
                : "bg-gray-500 text-white px-2 py-1 rounded-lg border border-gray-500 text-xs"
            }
            onClick={toggleActiveStatus}
          >
            {requirementData.status === "active" ? "Active" : "Inactive"}
          </button>
        </div>
        <div className="font-bold">{requirementData.tenantName}</div>
        <div className="text-xs">{requirementData.requirementType}</div>
        {requirementData.pipeline_status && (
          <div className="text-gray-600 font-bold uppercase text-xs mb-4">
            Pipeline Status: {requirementData.pipeline_status}
          </div>
        )}

        <div className="flex items-center gap-4 mt-4 flex-wrap">
          {menuItems.map((item, index) => {
            return (
              <div
                className={
                  activeLinkParam === item ? activePillStyle : pillStyle
                }
                key={index}
                onClick={() => handleActiveMenu(index)}
              >
                {item}
              </div>
            );
          })}
        </div>
      </div>

      <div>
        <div className="mt-4">
          <Button
            className="blue-button"
            label={editMode ? "Cancel Edit" : "Edit"}
            onClick={handleEditMode}
          />
        </div>

        {activeLinkParam === "Requirement Details" && (
          <div className="bg-white rounded-xl shadow-lg py-2 px-4 mt-4">
            <div>
              {!editMode ? (
                <RequirementDetailedInfo requirementData={requirementData} />
              ) : (
                <ReqDetailedInfoForm
                  requirementData={requirementData}
                  id={id}
                  setEditMode={setEditMode}
                />
              )}
            </div>

            <div className="text-xs text-gray-600 mt-4">
              {requirementData.lastUpdatedBy ? (
                <div>
                  <div>Last updated by:</div>
                  <div>
                    <UserAvatar email={requirementData.lastUpdatedBy} />
                  </div>
                  <div>
                    {new Date(requirementData.updatedAt).toLocaleString(
                      "en-US"
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        )}

        {activeLinkParam === "Requirement Details" && (
          <div className="bg-gray-100 rounded-xl shadow-lg py-2 px-4 mt-4">
            <div className="mt-4 ">
              <div className="header">Assign Team Members</div>
              <div>
                <h4 className=" text-sm mt-4">Select Team Members</h4>
                <div className="text-xs text-gray-700 ">
                  Click Edit Mode to add or remove team members
                </div>
                <UserSearch handleClick={handleClick} disabled={!editMode} />
                <h4 className="mt-4 text-sm ">Team Members</h4>
                {formData.team_members && (
                  <div className="flex flex-wrap text-xs">
                    {formData.team_members.map((member) => (
                      <div className=" flex flex-wrap mr-2 mb-1 " key={member}>
                        <div className=" p-2 ">
                          <ProfilePhotoOnly email={member} />
                        </div>
                        {member !== formData.team_lead && (
                          <button
                            className="bg-red-100 px-3 py-2 disabled:hidden"
                            onClick={(e) => handleDelete(e, member)}
                            disabled={!editMode}
                          >
                            x
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                )}

                {editMode ? (
                  <>
                    <h4 className="mt-4 text-sm">Team Lead</h4>
                    <InputBoxSelect
                      label={"Assign Team Lead for the Account"}
                      options={teamMembers}
                      placeholder="Select Team Lead"
                      name="team_lead"
                      onChange={handleSelectTeamLead}
                      disabled={!editMode}
                      value={teamMembers.map((option) => {
                        if (option.value === formData.team_lead) {
                          return option;
                        }
                      })}
                    />
                  </>
                ) : (
                  <>
                    <h4 className="text-sm mt-4">Team lead</h4>
                    <div className="text-sm">
                      <ProfilePhotoOnly email={formData.team_lead} />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        {activeLinkParam === "Company Profile" && (
          <div>
            <div>
              {!editMode ? (
                <div className="mt-4">
                  <CompanyProfile requirementData={requirementData} id={id} />
                </div>
              ) : (
                <CompanyProfileForm
                  requirementData={requirementData}
                  id={id}
                  setEditMode={setEditMode}
                />
              )}
            </div>
          </div>
        )}

        {activeLinkParam === "Company Profile" && (
          <div>
            <ContactList requirementData={requirementData} id={id} />
          </div>
        )}

        {activeLinkParam === "Team Notes" && (
          <div className="mt-8">
            <TeamNotes />
          </div>
        )}

        {activeLinkParam === "Options" && (
          <div className="bg-white rounded-xl shadow-lg py-2 px-4 mt-4">
            <div className="font-bold">Options</div>
            <div className="text-xs">{requirementData.options}</div>
          </div>
        )}

        {activeLinkParam === "Tasks" && (
          <div className="mt-8">
            <TaskManagement requirementData={requirementData} id={id} />
          </div>
        )}
      </div>
    </div>
  );
}

export default LeasingRequirementDetails;
