import React from "react";
import UseGetAllDoc from "../Hooks/UseGetAllDoc";
import PipelineCard from "./PipelineCard";

export default function CLPipeline() {
  const { data: accounts, isLoading } = UseGetAllDoc({
    collection: "tenantRep",
  });

  const [activePercentage, setActivePercentage] = React.useState("All");

  if (isLoading) {
    return <div>Loading...</div>;
  }

  //arrange accounts alphabetically using tenantName
  accounts.sort((a, b) => {
    if (a.tenantName < b.tenantName) {
      return -1;
    }
    if (a.tenantName > b.tenantName) {
      return 1;
    }
    return 0;
  });

  const statusPercentages = ["All", "10%", "30%", "50%", "90%", "100%"];

  const handleActivePercentage = (e) => {
    setActivePercentage(e.target.innerText);
  };

  const filteredAccounts = accounts.filter(
    (account) =>
      activePercentage === "All" || account.pipeline_status === activePercentage
  );

  return (
    <div className="space-y-8">
      <div className="header">Commercial Leasing Pipeline</div>

      <div className="text-sm uppercase font-bold text-gray-600">
        Total Accounts: {filteredAccounts.length}
      </div>

      <div>
        <div>Filters</div>
        <div className="flex items-center gap-4">
          {statusPercentages.map((status, index) => (
            <div
              key={index}
              className={
                activePercentage === status ? "active-pill" : "inactive-pill"
              }
              onClick={handleActivePercentage}
            >
              {status}
            </div>
          ))}
        </div>
      </div>

      <div></div>

      <div className="space-y-4">
        {filteredAccounts.map((account, index) => (
          <PipelineCard key={index} account={account} />
        ))}
      </div>
    </div>
  );
}
