import React, { useEffect } from "react";
import {
  countDocsByStatusAndEmail,
  queryOnSnapshot,
  getAllUserTasks,
} from "../../Firebase/Firebase.utils";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loadListing } from "../../store/userSlice";
import { useDispatch } from "react-redux";

import { motion } from "framer-motion";
import { getAllDocs } from "../../Firebase/Firebase.utils";

import LeasingAccountCountCard from "./LeasingAccountCountCard";
import ISAccountCountCard from "../../InvestmentSales/ISAccountCountCard";

function Dashboard() {
  const currentUser = useSelector((state) => state.userObject);
  const [pendingBillings, setPendingBillings] = React.useState(0);
  const Navigate = useNavigate();
  const [listings, setListings] = React.useState([]);
  const [accountCount, setAccountCount] = React.useState(0);
  const [potentialFee, setPotentialFee] = React.useState(0);
  const [tasks, setTasks] = React.useState([]);
  const [openTasks, setOpenTasks] = React.useState([]);
  const [assignedTasks, setAssignedTasks] = React.useState([]);
  const [isProcessing, setIsProcessing] = React.useState(false);

  const dispatch = useDispatch();

  //use effect to get all pending billings
  useEffect(() => {
    setIsProcessing(true);
    const fetchPendingBillings = async () => {
      try {
        const pendingBillings = await countDocsByStatusAndEmail(
          "billing",
          currentUser.email,
          "pending approval",
          "approver"
        );

        setPendingBillings(pendingBillings);
        setIsProcessing(false);
      } catch (error) {}
    };
    fetchPendingBillings();
  }, []);

  //button function to navigate to pending billings
  const handleNavigate = () => {
    Navigate("/billinglist/myBillings");
  };

  //button function to navigate to all listings
  const handleNavigateListings = () => {
    if (currentUser.department === "Hospitality") {
      Navigate("/hospitality");
    } else {
      Navigate("/all-listings");
    }
  };

  //get all tasks assigned to the user

  useEffect(() => {
    const tasksArray = [];
    const getTasks = async () => {
      try {
        const res = getAllUserTasks("tasks", currentUser.email, (data) => {
          setTasks(data);
        });
      } catch (error) {}
    };
    getTasks();
  }, []);

  //count all tasks assigned to the user

  const firstName = currentUser.displayName.split(" ")[0];

  if (isProcessing === true)
    return (
      <div className="">
        <div>Loading...</div>
      </div>
    );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="flex flex-col flex-wrap items-center justify-center max-sm:w-screen text-gray-600"
    >
      <div className="uppercase font-bold text-leechiuBlue p-2 w-full text-lg">
        Welcome to your Dashboard
      </div>

      <div
        className="flex items-center justify-start gap-2 max-sm:flex-col max-sm:w-full max-sm:items-start flex-wrap"
        // container for the grid
      >
        <div
          className="w-full lg:w-[700px] flex flex-col items-between justify-start gap-2 max-sm:w-[350px]"
          // left side of the grid
        >
          <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:w-full">
            {/* card 1 */}
            <div
              className="max-sm:w-full w-[380px] h-[100px] rounded-xl overflow-hidden border-2 border-gray-300  hover:shadow-lg cursor-pointer"
              style={{
                transition: "all 0.2s ease-in-out",
              }}
            >
              <div className="bg-gray-100 p-2 flex items-center justify-between border-b-2">
                <div className="uppercase font-bold text-sm text-leechiuBlue">
                  Listings
                </div>
                <div className="font-semibold text-xs text-leechiuBlue">
                  View All Listings
                </div>
              </div>

              {currentUser.department === "Investment Sales" && (
                <div className=" p-2 font-bold text-leechiuBlue text-lg bg-gray-50 h-full">
                  <ISAccountCountCard />
                </div>
              )}

              {currentUser.department === "Commercial Leasing" && (
                <div>
                  <LeasingAccountCountCard />
                </div>
              )}
            </div>
            {/* card no. 2 */}
            <div
              className="max-sm:w-full w-[300px] overflow-hidden border-2 rounded-xl border-gray-300 cursor-pointer  hover:shadow-lg"
              style={{
                transition: "all 0.2s ease-in-out",
              }}
              onClick={handleNavigate}
            >
              <div className="flex items-center justify-between border-b-2 border-gray-300 p-2 bg-gray-100 ">
                <div className="uppercase font-bold text-sm text-leechiuBlue">
                  For approval
                </div>
                <div className="font-semibold text-xs text-leechiuBlue">
                  View All Pending Billings
                </div>
              </div>
              <div className=" p-4 font-bold text-leechiuBlue bg-gray-50">
                <span className="text-leechiuOrange">{pendingBillings}</span>{" "}
                Pending Billings
              </div>
            </div>
          </div>

          {currentUser.department === "Investment Sales" && (
            <div className="bg-white p-2 rounded-xl text-gray-600 text-sm">
              <div className="font-bold">Quick Links</div>

              <div className="mt-4 underline font-bold text-leechiuOrange uppercase">
                <Link to="/dashboard/is-pipeline">IS Pipeline per Agent</Link>
              </div>
              <div></div>
            </div>
          )}

          {currentUser.roles.includes("cl admin") && (
            <div className="bg-white p-2 rounded-xl text-gray-600 text-sm">
              <div className="font-bold">Quick Links</div>

              <div className="mt-4 underline font-bold text-leechiuOrange uppercase">
                <Link to="/dashboard/cl-pipeline">CL Pipeline Report</Link>
              </div>

              <div></div>
            </div>
          )}

          <div className="rounded-xl h-[280px] w-full bg-gray-50 border-gray-300 border-2 overflow-hidden">
            <div className="flex items-center justify-between border-b-2 border-gray-300 p-2 bg-gray-100">
              <div className="uppercase font-bold text-leechiuBlue text-sm">
                Quota vs Progress
              </div>
              <div className="uppercase font-bold text-leechiuBlue text-xs">
                View Complete Data
              </div>
            </div>
          </div>

          <div className="rounded-xl h-[200px] w-full bg-gray-50 border-gray-300 border-2 overflow-hidden">
            <div className="flex items-center justify-between border-b-2 border-gray-300 p-2 bg-gray-100">
              <div className="uppercase font-bold text-leechiuBlue text-sm">
                Latest Agents Posts
              </div>
              <div className="uppercase font-bold text-leechiuBlue text-xs">
                View All Posts
              </div>
            </div>
          </div>
        </div>
        <div className="w-[700px] flex flex-row items-start justify-start gap-2">
          <div className="w-full rounded-xl border-2 border-gray-300 h-[300px] bg-gray-50 overflow-hidden">
            <div className="flex items-center justify-between border-b-2 border-gray-300 bg-gray-100 p-2">
              <div className="uppercase text-xs font-bold text-leechiuBlue">
                Tasks Assigned to you
              </div>
              <div className="uppercase text-xs font-semibold text-leechiuBlue">
                0 Open Tasks
              </div>
            </div>
          </div>
          <div className="w-full h-[300px] border-2 border-gray-300 rounded-xl bg-gray-50 overflow-hidden">
            <div className="flex items-center justify-between p-2 bg-gray-100 border-b-2 border-gray-300">
              <div className="uppercase text-leechiuBlue text-xs font-bold">
                Notes
              </div>
              <div className="uppercase text-leechiuBlue text-xs font-semibold">
                Show All Notes
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Dashboard;
