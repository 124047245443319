import React from "react";
import ProfilePhotoOnly from "../components/ProfilePhotoOnly";
import { setDocData, deleteDocById } from "../Firebase/Firebase.utils";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";

export default function PipelineCard({ account }) {
  const navigate = useNavigate();
  const [activePercentage, setActivePercentage] = React.useState(0);
  const currentUser = useSelector((state) => state.userObject);

  const updateStatus = async () => {
    const newStatus = account.status === "active" ? "inactive" : "active";
    const docObject = {
      status: newStatus,
      updatedBy: currentUser.email,
      updatedAt: new Date().toLocaleString(),
      updateType: "status",
    };

    await setDocData("tenantRep", account.id, docObject);
    toast.success("Status updated successfully ");
  };

  const handleNavigate = () => {
    navigate(`/leasing/tenantRep/req/${account.id}?link=Requirement+Details`);
  };

  const statusPercentages = ["10%", "30%", "50%", "90%", "100%"];

  const handleSetPercentage = async (e) => {
    setActivePercentage(e.target.innerText);
    const docObject = {
      pipeline_status: e.target.innerText,
      updatedBy: currentUser.email,
      updatedAt: new Date().toLocaleString(),
      updateType: "pipeline_status",
    };

    await setDocData("tenantRep", account.id, docObject);
    toast.success("Pipeline status updated");
  };

  const handleDelete = async () => {
    if (currentUser.roles.includes("admin")) {
      if (window.confirm("Are you sure you want to delete this account?")) {
        await deleteDocById("tenantRep", account.id);
        toast.success("Account deleted successfully");
      }
    } else {
      toast.error("You are not authorized to delete this account");
    }
  };

  return (
    <div className="relative bubble text-sm text-gray-600  rounded-xl">
      <div
        className="text-xs underline absolute bottom-2 right-2 cursor-pointer"
        onClick={handleNavigate}
      >
        See Details
      </div>

      {/* {currentUser.roles.includes("admin") && (
        <div className="absolute top-1 right-16">
          <button className="blue-button" onClick={handleDelete}>
            delete
          </button>
        </div>
      )} */}

      <div
        className={`absolute top-1 right-1 rounded-lg text-white text-xs px-2 py-1 cursor-pointer ${
          account.status === "active" ? "bg-leechiuOrange" : "bg-gray-500"
        }`}
        onClick={updateStatus}
      >
        {account.status || "active"}
      </div>

      <div className="flex items-center gap-4 mb-4">
        <div className="font-bold text-lg">{account.tenantName}</div>

        <div className="text-xs text-gray-600">
          {account.requirementLocation}
        </div>
        <div className="text-xs text-gray-600">{account.requirementType}</div>
        <div className="text-xs text-gray-600">
          {account.requirementSize} sqms
        </div>
      </div>

      <div className="flex items-center justify-normal gap-4 ">
        <div>
          Pipeline Status :{" "}
          {account.pipeline_status === undefined
            ? "Set pipeline % status"
            : null}
        </div>

        <div className="flex items-center gap-4">
          {statusPercentages.map((percentage, index) => (
            <div
              className={
                account.pipeline_status === percentage
                  ? "active-pill"
                  : "inactive-pill"
              }
              onClick={handleSetPercentage}
            >
              {percentage}
            </div>
          ))}
        </div>
      </div>

      <div className="flex items-center gap-4 mt-8">
        {account.team_members?.map((member, index) => {
          return (
            <div key={index}>
              <ProfilePhotoOnly email={member} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
