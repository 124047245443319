import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getAllDocsByEmail,
  getAllDocsByEmailAndStatus,
} from "../../Firebase/Firebase.utils";
import { Link, useNavigate } from "react-router-dom";
import BillingCard from "../../components/BillingCard";
import LeaseBillingCard from "./LeaseBillingCard";
import ResiLeaseBillingCard from "../Residential/ResiLeaseBillingCard";

function BillingPending() {
  const currentUser = useSelector((state) => state.userObject);
  const [billingList, setBillingList] = React.useState([]);
  const [billingForApproval, setBillingForApproval] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [billingsApproved, setBillingsApproved] = React.useState([]);

  const Navigate = useNavigate();

  useEffect(() => {
    if (!currentUser.email || currentUser.email === null) {
      Navigate("/login");
    }
  }, []);

  useEffect(() => {
    const fetchMyBillings = async () => {
      try {
        setIsLoading(true);
        const myBillings = await getAllDocsByEmail(
          "billing",
          currentUser.email,
          "created_by"
        );

        setBillingList(myBillings);
        setIsLoading(false);
      } catch (error) {}
    };
    fetchMyBillings();
  }, []);

  useEffect(() => {
    const fetchApprovalList = async () => {
      try {
        const myApprovalList = await getAllDocsByEmailAndStatus(
          "billing",
          currentUser.email,
          "pending approval",
          "approver"
        );

        setBillingForApproval(myApprovalList);
      } catch (error) {}
    };
    fetchApprovalList();
  }, []);

  useEffect(() => {
    const billingsApprovedList = async () => {
      try {
        const myApprovalList = await getAllDocsByEmailAndStatus(
          "billing",
          currentUser.email,
          "approved",
          "approver"
        );

        setBillingsApproved(myApprovalList);
      } catch (error) {}
    };
    billingsApprovedList();
  }, []);

  const Headers = () => {
    return (
      <div className="flex border-t-gray-200 border-b mb-2 py-2  uppercase">
        <p className="w-72 text-gray-600">Billing ID</p>
        <p className="w-64  text-gray-600">Approver</p>
        <p className="w-64  text-gray-600">Client Name</p>
        <p className="w-64  text-gray-600">Total Amount</p>
        <p className="w-64  text-gray-600">Status</p>
      </div>
    );
  };

  const HeadersForApproval = () => {
    return (
      <div className="flex border-t-gray-200 border-b mb-2 py-2  uppercase">
        <p className="w-72 text-gray-600">Billing ID</p>
        <p className="w-64  text-gray-600">Requestor</p>
        <p className="w-64  text-gray-600">Client Name</p>
        <p className="w-64  text-gray-600">Total Amount</p>
        <p className="w-64  text-gray-600">Status</p>
      </div>
    );
  };

  return (
    <div>
      <h4 className="mt-8">
        The following shows all the billings for your approval
      </h4>
      {isLoading && <p>Loading...</p>}

      {billingForApproval.length === 0 && (
        <p className="italic">You dont have any pending approvals</p>
      )}
      {billingForApproval.map((billing) =>
        billing.billing_type === "lease" ? (
          <div>
            <Link to={`/leaseBillingView/${billing.id}`} key={billing.id}>
              <LeaseBillingCard billing={billing} />
            </Link>
          </div>
        ) : billing.billing_type === "sale" ? (
          <Link to={`/billingview/${billing.id}`} key={billing.id}>
            {/* Wrap BillingCard with Link */}
            <BillingCard billing={billing} />
          </Link>
        ) : billing.billing_type === "residentialLease" ? (
          <div>
            <Link
              to={`/residentialLeaseBillingView/${billing.id}`}
              key={billing.id}
            >
              <ResiLeaseBillingCard billing={billing} />
            </Link>
          </div>
        ) : null
      )}
    </div>
  );
}

export default BillingPending;
