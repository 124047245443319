import * as React from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useNavigate } from "react-router-dom";
import SidebarOption from "../components/SidebarOption";
import { IoAppsSharp } from "react-icons/io5";
import { IoList } from "react-icons/io5";
import { BsBuildingCheck } from "react-icons/bs";
import { HiMiniBuildingOffice2 } from "react-icons/hi2";
import { MdForum } from "react-icons/md";
import { RiBillLine } from "react-icons/ri";
import { FaPeopleArrows } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa";
import ProfilePicture from "../components/ProfilePicture";
import { logOutUser } from "../Firebase/Firebase.utils";
import { HiDocumentReport } from "react-icons/hi";

const drawerWidth = 250;

function ResponsiveDrawer(props) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.userObject);

  const Navigate = useNavigate();

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const sideBarLinks = [
    {
      link: "dashboard",
      label: "Dashboard",
      icon: <IoAppsSharp />,
    },

    {
      link: "tasks",
      label: "All Tasks",
      icon: <IoList />,
    },

    {
      link: "all-listings",
      label: "All Listings",
      icon: <BsBuildingCheck />,
    },
    // {
    //   link: "hospitality",
    //   label: "Hospitality",
    //   icon: <HomeWorkIcon className="mr-4" />,
    // },
    // {
    //   link: "residential",
    //   label: "Residential",
    //   icon: <PiHouseLineLight />,
    // },
    {
      link: "leasing?link=Masterlist",
      label: "Office Leasing",
      icon: <HiMiniBuildingOffice2 />,
    },
    {
      link: "research",
      label: "Research and Valuation",
      icon: <HiDocumentReport />,
    },
    {
      link: "discussion?link=general",
      label: "Discussion Board",
      icon: <MdForum />,
    },

    {
      link: "billing",
      label: "Request to Bill",
      icon: <RiBillLine />,
    },
    {
      link: "billinglist/mybillings",
      label: "View All Billings",
      icon: <RiBillLine />,
    },
    {
      link: "myclients",
      label: "Clients Database",
      icon: <FaPeopleArrows />,
    },
    {
      link: "clients_db",
      label: "Log Client Interaction",
      icon: <FaPeopleArrows />,
    },
    {
      link: "myprofile",
      label: "My Profile",
      icon: <FaRegUser />,
    },
  ];

  const approverSidebarLinks = [
    {
      link: "billinglist/billingsPending",
      label: "Billings for Approval",
      icon: <RiBillLine />,
    },
  ];

  const adminSidebarLinks = [
    {
      link: "userAccess",
      label: "User Access ",
      icon: <FaRegUser />,
    },
  ];

  const financeSidebarLinks = [
    {
      link: "billinglist/financePending",
      label: "Finance for Approval",
      icon: <RiBillLine />,
    },
  ];

  const drawer = (
    <div className="bg-gray-200 min-h-screen border-0 p-2 text-gray-600">
      <div className="flex items-center justify-center mb-4">
        <img
          src="https://res.cloudinary.com/dtnbpkyvd/image/upload/v1721711625/leechiuweb3/newlogo_leechiu_sm3mgv_1_mop8e5.png"
          className="h-16 "
          alt=""
        />
      </div>

      <div>
        <ProfilePicture />
      </div>

      <div className="bg-white p-2 rounded-xl">
        {sideBarLinks.map((item) => (
          <SidebarOption link={item.link} label={item.label} key={item.label}>
            <span className="text-warmgray-800 text-sm "> {item.icon}</span>
          </SidebarOption>
        ))}
      </div>

      <div className="mt-2 rounded-xl bg-white">
        {currentUser.roles && currentUser.roles.includes("approver") && (
          <h4 className=" p-2 uppercase text-sm text-leechiuBlue">
            Approver Functions
          </h4>
        )}

        {currentUser.roles &&
          currentUser.roles.includes("finance") &&
          financeSidebarLinks.map((item) => (
            <SidebarOption link={item.link} label={item.label} key={item.label}>
              <span className="text-warmgray-800 "> {item.icon}</span>
            </SidebarOption>
          ))}

        {currentUser.roles &&
          currentUser.roles.includes("approver") &&
          approverSidebarLinks.map((item) => (
            <SidebarOption link={item.link} label={item.label} key={item.label}>
              <span className="text-warmgray-800 "> {item.icon}</span>
            </SidebarOption>
          ))}
        {currentUser.roles && currentUser.roles.includes("admin") && (
          <h4 className="p-2 uppercase text-leechiuBlue font-bold text-sm">
            Admin Functions
          </h4>
        )}
        {currentUser.roles &&
          currentUser.roles.includes("admin") &&
          adminSidebarLinks.map((item) => (
            <SidebarOption link={item.link} label={item.label} key={item.label}>
              <span className="text-warmgray-800 "> {item.icon}</span>
            </SidebarOption>
          ))}
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  if (currentUser.roles.includes("restricted")) {
    return (
      <div>
        <div className="bg-red-200 p-4 text-center">
          <h1 className="text-red-800 font-bold">Restricted Access</h1>
          <p>
            You do not have access to this page. Please contact your
            administrator for more information.
          </p>
        </div>
      </div>
    );
  }

  const handleLogout = () => {
    logOutUser();
    Navigate("/login");
  };

  return (
    <div className="text-gray-600 text-xs">
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            backgroundColor: "#002368",
            zIndex: 100,
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <div className="flex flex-row justify-between w-full items-center">
              {/* <div className="flex gap-x-2 items-center">
                {!!currentUser.email && (
                  <AvatarName displayName={currentUser.displayName} />
                )}
                {currentUser.displayName}
              </div> */}
              {/* container of icons */}
              <div></div>
              <div className="flex gap-x-4 items-center ">
                <NotificationsNoneIcon />
                <MailOutlineIcon />
                <button className="blue-button" onClick={handleLogout}>
                  Log Out
                </button>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none", zIndex: 100 },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block", zIndex: 100 },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                zIndex: 10,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <div
          className="bg-gray-200 flex grow-1"
          style={{ width: "100%", minHeight: "100vh" }}
        >
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
          >
            <Toolbar />
            <Outlet />
          </Box>
        </div>
      </Box>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
