import React from "react";

function ButtonWhite({ label, ...otherprops }) {
  return (
    <>
      <button
        className="bg-white border-solid border-gray-900 border-[1px] hover:text-white hover:bg-gray-700 font-semibold text-black py-2 px-4 rounded w-fit disabled:opacity-50 disabled:bg-slate-500 text-sm"
        {...otherprops}
      >
        {label}
      </button>
    </>
  );
}

export default ButtonWhite;
