import React from "react";
import Select from "react-select";

export default function ReactSelectBox({
  label,
  options,
  value,
  onChange,
  ...otherprops
}) {
  return (
    <div>
      <label className="text-sm font-semibold text-gray-600 uppercase">
        {label}
      </label>
      <Select
        className="disabled:text-gray-800 disabled:bg-gray-200 w-80 px-4 py-2 border rounded-md border-gray-400 focus:outline-none focus:border-blue-500"
        value={value}
        onChange={onChange}
        options={options}
        {...otherprops}
      />
    </div>
  );
}
