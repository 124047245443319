import React from "react";
import { useState, useEffect } from "react";
import { getAllDocs } from "../Firebase/Firebase.utils";

function UserSearch({ handleClick, disabled }) {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const res = await getAllDocs("users");
        const sortUsers = res.sort((a, b) => {
          return a.displayName.localeCompare(b.displayName);
        });

        setUsers(sortUsers);
        setFilteredUsers(sortUsers);
        setLoading(false);
      } catch (error) {}
    };
    getUsers();
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    const filteredUsers = users.filter((user) => {
      return user.displayName.toLowerCase().includes(search.toLowerCase());
    });
    setFilteredUsers(filteredUsers);
  };

  const handleUserClick = (e, user) => {
    handleClick(e, user);
    setSearch(""); // Reset the search input after clicking a user
  };

  return (
    <div className="relative">
      <input
        type="text"
        placeholder="Search user here"
        value={search}
        onChange={handleSearch}
        disabled={disabled}
        className="disabled:text-gray-800 disabled:bg-gray-200 w-80 px-4 py-2 mt-2 border rounded-md border-gray-400 focus:outline-none focus:border-blue-500 overflow-hidden"
      />
      {search.length > 0 && (
        <div className="absolute left-0 right-0 bg-white border rounded-md border-gray-400 z-10 overflow-hidden font-semibold">
          {filteredUsers.slice(0, 5).map((user) => (
            <div
              className="hover:bg-gray-200 text-sm flex flex-wrap p-2 cursor-pointer border-b border-gray-300"
              key={user.email}
            >
              <p
                className="capitalize"
                onClick={(e) => handleUserClick(e, user)}
              >
                {user.displayName.toLowerCase()}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default UserSearch;
