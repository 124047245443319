import React from "react";
import Button from "../../components/Button";
import { useEffect, useState } from "react";
import {
  getAllDocsSnap,
  createNewDocument,
  getAllDocsSnapByEmail,
} from "../../Firebase/Firebase.utils";
import InputBox from "../../components/InputBox";
import ReactSelect from "react-select";
import { useSelector } from "react-redux";
import { create } from "@mui/material/styles/createTransitions";
import ButtonWhite from "../../components/ButtonWhite";
import TenantCards from "./TenantCards";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function TenantRep() {
  const [formData, setFormData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [createMode, setCreateMode] = useState(false);
  const [tenantList, setTenantList] = useState([]);
  const [allRequirements, setAllRequirements] = useState([]);
  const currentUser = useSelector((state) => state.userObject);
  const navigate = useNavigate();

  useEffect(() => {
    const getAllDocs = async () => {
      const res = await getAllDocsSnapByEmail(
        "tenantRep",
        currentUser.email,
        (data) => {
          setTenantList(data);
        }
      );
    };
    getAllDocs();
  }, []);

  useEffect(() => {
    const getAllRequirements = async () => {
      const res = await getAllDocsSnap("tenantRep", (data) => {
        setAllRequirements(data);
      });
    };
    getAllRequirements();
    if (currentUser.roles.includes("admin")) {
      setTenantList(allRequirements);
    }
  }, [tenantList]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const Industries = [
    "Accounting",
    "Airlines/Aviation",
    "Alternative Dispute Resolution",
    "Alternative Medicine",
    "Animation",
    "Apparel & Fashion",
    "Architecture & Planning",
    "Arts & Crafts",
    "Automotive",
    "Aviation & Aerospace",
    "Banking",
    "Biotechnology",
    "Broadcast Media",
    "Building Materials",
    "Business Supplies & Equipment",
    "Capital Markets",
    "Chemicals",
    "Civic & Social Organization",
    "Civil Engineering",
    "Commercial Real Estate",
    "Computer & Network Security",
    "Computer Games",
    "Computer Hardware",
    "Computer Networking",
    "Computer Software",
    "Construction",
    "Consumer Electronics",
    "Consumer Goods",
    "Consumer Services",
    "Cosmetics",
    "Dairy",
    "Defense & Space",
    "Design",
    "Education Management",
    "E-Learning",
    "Electrical/Electronic Manufacturing",
    "Entertainment",
    "Environmental Services",
    "Events Services",
    "Executive Office",
    "Facilities Services",
    "Farming",
    "Financial Services",
    "Fine Art",
    "Fishery",
    "Food & Beverages",
    "Food Production",
    "Fund-Raising",
    "Furniture",
    "Gambling & Casinos",
    "Glass, Ceramics & Concrete",
    "Government Administration",
    "Government Relations",
    "Graphic Design",
    "Health, Wellness & Fitness",
    "Higher Education",
    "Hospital & Health Care",
    "Hospitality",
    "Human Resources",
    "Import & Export",
    "Individual & Family Services",
    "Industrial Automation",
    "Information Services",
    "Information Technology & Services",
    "IT-BPM",
    "Insurance",
    "International Affairs",
    "International Trade & Development",
    "Internet",
    "Investment Banking",
    "Investment Management",
    "Judiciary",
    "Law Enforcement",
    "Law Practice",
    "Legal Services",
    "Legislative Office",
    "Leisure, Travel & Tourism",
    "Libraries",
    "Logistics & Supply Chain",
    "Luxury Goods & Jewelry",
    "Machinery",
    "Management Consulting",
    "Maritime",
    "Market Research",
    "Marketing & Advertising",
    "Mechanical or Industrial Engineering",
    "Media Production",
    "Medical Devices",
    "Medical",
    "Medical Practice",
    "Mental Health Care",
    "Military",
    "Mining & Metals",
    "Motion Pictures & Film",
    "Museums & Institutions",
    "Music",
    "POGO",
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);

    const docObject = {
      createdAt: new Date(),
      createdBy: currentUser.email,
      team_members: [currentUser.email],
      team_lead: currentUser.email,
      ...formData,
    };
    const createObject = async () => {
      const res = await createNewDocument("tenantRep", docObject);
      if (res) {
        alert("Successfully created tenant requirement");
        setIsProcessing(false);
        setCreateMode(false);
      }
    };
    createObject();
    setFormData({});
  };

  const handleCreate = () => {
    setCreateMode(true);
  };

  const handleCancel = () => {
    setCreateMode(false);
    setFormData({});
  };

  const handleNavigate = (id) => {
    navigate(`/leasing/tenantRep/req/${id}?link=Requirement+Details`);
  };

  return (
    <div className="max-w-[1800px] mt-4">
      <div>
        <div className="mt-4">
          <Button label="Create A New Requirement" onClick={handleCreate} />
        </div>

        {createMode && (
          <div className="bg-white rounded-xl shadow-lg px-4 py-2 mt-8">
            <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
              <div className="font-bold">Tenant Details</div>
              <div className="flex items-center gap-4 flex-wrap">
                <InputBox
                  label="Tenant Name"
                  placeholder={"e.g. Hinduja Global Solutions"}
                  onChange={handleChange}
                  name="tenantName"
                  required={true}
                  value={formData.tenantName}
                />
                <InputBox
                  label="Tenant Contact Person"
                  placeholder={"e.g. John Doe"}
                  onChange={handleChange}
                  name="tenantContactPerson"
                  required={true}
                  value={formData.tenantContactPerson}
                />
                <InputBox
                  label="Tenant Contact Number"
                  placeholder={"e.g. 09123456789"}
                  onChange={handleChange}
                  name="tenantContactNumber"
                  required={true}
                  value={formData.tenantContactNumber}
                />
                <InputBox
                  label="Tenant Email Address"
                  placeholder={"Enter Email Address"}
                  onChange={handleChange}
                  name="tenantEmail"
                  required={true}
                  value={formData.tenantEmail}
                />
                <InputBox
                  label="Tenant Industry"
                  placeholder={"e.g. BPO"}
                  onChange={handleChange}
                  name="tenantIndustry"
                  required={true}
                  value={formData.tenantIndustry}
                />
              </div>

              <div>Requirement Details</div>
              <div className="flex items-center gap-4 flex-wrap">
                <InputBox
                  label="Requirement Type"
                  placeholder={"e.g. Office"}
                  name="requirementType"
                  required={true}
                  value={formData.requirementType}
                  onChange={handleChange}
                />
                <InputBox
                  label="Requirement Size in sqms"
                  placeholder={"e.g. 1000 sqm"}
                  type={"number"}
                  name="requirementSize"
                  required={true}
                  value={formData.requirementSize}
                  onChange={handleChange}
                />
                <InputBox
                  label="Requirement Location"
                  placeholder={"e.g. BGC"}
                  name="requirementLocation"
                  required={true}
                  value={formData.requirementLocation}
                  onChange={handleChange}
                />
                <InputBox
                  label="Requirement Budget"
                  placeholder={"e.g. 100000"}
                  name="requirementBudget"
                  required={true}
                  value={formData.requirementBudget}
                  onChange={handleChange}
                />
                <InputBox
                  label="Requirement Term"
                  placeholder={"e.g. 5 years"}
                  name="requirementTerm"
                  required={true}
                  value={formData.requirementTerm}
                  onChange={handleChange}
                />
                <InputBox
                  label="Requirement Move-In Date"
                  placeholder={"e.g. 2022-01-01"}
                  name="requirementMoveInDate"
                  type={"date"}
                  required={true}
                  value={formData.requirementMoveInDate}
                  onChange={handleChange}
                />

                <InputBox
                  label="Requirement Special Request"
                  placeholder={"e.g. High Ceiling"}
                  name="requirementSpecialRequest"
                  required={true}
                  value={formData.requirementSpecialRequest}
                  onChange={handleChange}
                />
              </div>
              <div className="flex items-center gap-4">
                <Button
                  label={isProcessing ? "Processing..." : "Submit Requirement"}
                  disabled={isProcessing}
                />
                <ButtonWhite
                  type="button"
                  label="Cancel"
                  onClick={handleCancel}
                />
              </div>
            </form>
          </div>
        )}
      </div>

      <div>
        <div className="my-4 font-bold text-gray-800 uppercase text-sm">
          Tenant Requirements List
        </div>
        <div>Total Accounts: {tenantList.length}</div>
        <div>
          {tenantList.map((tenant) => (
            <div className="my-2" key={tenant.id}>
              <div onClick={() => handleNavigate(tenant.id)}>
                <TenantCards tenant={tenant} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TenantRep;
